 <!-- Header Section -->
 <header class="header p-2 shadow">
    <div class="container">
      <!-- Navbar Header -->
      <nav class="navbar navbar-expand-lg">
        <!-- Logo -->
        <a class="navbar-brand" href="/home">
          <img src="../../../assets/images/Mechtron Services.jpg" height="50" alt="">
        </a>

        <!-- Hamburger Menu (Visible on smaller screens) -->
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <!-- Navbar Links (Collapsed for mobile view) -->
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <a class="nav-link text-secondary" [ngClass]="{'active border-bottom border-3 border-secondary':  isRouteActive('/home')}" (click)="changeTab(1)" aria-current="page" routerLink="/home">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-secondary" routerLink="/about-us" [ngClass]="{'active border-bottom border-3 border-secondary':  isRouteActive('/about-us')}" (click)="changeTab(2)">About Us</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link text-secondary dropdown-toggle" [ngClass]="{'active border-bottom border-3 border-secondary': isRouteActive('/all-service')}" (click)="changeTab(3)" [matMenuTriggerFor]="menu" role="button" aria-expanded="false">
                Services
              </a>
              <mat-menu #menu="matMenu">
                <button mat-menu-item routerLink="/all-service">Sewage Treatment Plant (MBBR, MBR, SBR, Conventional)</button>
                <button mat-menu-item routerLink="/all-service">Effluent Treatment Plant</button>
                <button mat-menu-item routerLink="/all-service">Dissolved Air Floatation</button>
                <button mat-menu-item routerLink="/all-service">CPI Separator</button>
                <button mat-menu-item routerLink="/all-service">Oil Skimmer</button>
                <button mat-menu-item routerLink="/all-service">Oil & Grease Interceptor</button>
                <button mat-menu-item routerLink="/all-service">Agitator for Chemical Mixing</button>
                <button mat-menu-item routerLink="/all-service">Lamella Clarifier</button>
                <button mat-menu-item routerLink="/all-service">Clarifier</button>
                <button mat-menu-item routerLink="/all-service">HRSCC</button>
                <button mat-menu-item routerLink="/all-service">Filters (PSF, ACF, DMF, etc.)</button>
                <button mat-menu-item routerLink="/all-service">Self Cleaning Filter</button>
                <button mat-menu-item routerLink="/all-service">Surface Aerator (Fixed & Floating)</button>
                <button mat-menu-item routerLink="/all-service">Dosing System</button>
                <button mat-menu-item routerLink="/all-service">Spares & Consumables (Diffuser, Membrane, Chemicals, etc.)</button>
              </mat-menu>
            </li>
            <li class="nav-item">
              <a routerLink="/contact-us" class="btn btn-success rounded-pill">Contact us</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </header>